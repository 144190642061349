import * as R from 'ramda';
import { QueryOperant } from '@/app/constants';
import { S } from '@/app/utilities';

export function useModel() {
    /**
     * Filters and flattens a given list of concepts based on the filterCheck function.
     * Also adds to it the domain and parent
     *
     * @param items - List of concept trees
     * @param filterCheck - Function that returns true or false if we want to include the concept in the list or not
     * @param pathPrefix - The path prefix based on the concept parents
     * @param dataModel - the data model this belongs to (top level concept)
     *
     * @returns A list of concepts containing also domain and parent
     */
    const filterConceptsList = (items: any[], filterCheck: any, pathPrefix = '', dataModel = ''): any => {
        return items.reduce((accumulator: any, concept: any) => {
            const path = `${pathPrefix}${concept.name}`;
            if (filterCheck(path, concept)) {
                const clonedAccumulator = R.clone(accumulator);
                clonedAccumulator[path] = concept;
                clonedAccumulator[path].domain = dataModel;
                clonedAccumulator[path].parent =
                    pathPrefix.charAt(pathPrefix.length - 1) === '.'
                        ? pathPrefix.substring(0, pathPrefix.length - 1)
                        : pathPrefix;
                return clonedAccumulator;
            }
            if (concept.children) {
                return { ...accumulator, ...filterConceptsList(concept.children, filterCheck, `${path}.`, dataModel) };
            }

            return accumulator;
        }, []);
    };

    /**
     * This is the exposed function using the filterConceptsList function.
     * Iterates over the list of models and concacts or lists of concepts from each model into one list
     *
     * @param dataModel - The data model tree to filter
     * @param filterCheck -Function that returns true or false if we want to include the concept in the list or not
     *
     * @returns A list of concepts containing also domain and parent
     */
    const filterConcepts = (dataModel: any, filterCheck: any): any => {
        return Object.keys(dataModel).reduce((accumulator: any, key: string) => {
            return {
                ...accumulator,
                ...filterConceptsList(dataModel[key].children, filterCheck, '', dataModel[key].name),
            };
        }, {});
    };

    /**
     * Returns the operants for a given type
     *
     * @param conceptType - The type of the concept
     *
     * @returns A list of QueryOperants
     */
    const typeOperants = (conceptType: string): QueryOperant[] => {
        switch (conceptType) {
            case 'double':
            case 'integer':
            case 'date':
            case 'datetime':
            case 'time':
                return [
                    QueryOperant.EQUALS,
                    QueryOperant.NOT_EQUALS,
                    QueryOperant.GREATER_THAN,
                    QueryOperant.GREATER_THAN_OR_EQUAL_TO,
                    QueryOperant.LESS_THAN,
                    QueryOperant.LESS_THAN_OR_EQUAL_TO,
                ];
                break;

            case 'boolean':
                return [QueryOperant.EQUALS, QueryOperant.NOT_EQUALS];
            case 'string':
                return [
                    QueryOperant.EQUALS,
                    QueryOperant.NOT_EQUALS,
                    QueryOperant.CONTAINS,
                    QueryOperant.STARTS_WITH,
                    QueryOperant.ENDS_WITH,
                ];
            default:
                return [];
        }
    };

    /**
     * Returns a fixed list of options that the type can take if applicable
     *
     * @param conceptType - The type of the concept
     *
     * @returns A fixed list of options the type can take
     */
    const typeOptions = (conceptType: string): { key: any; name: string }[] => {
        switch (conceptType) {
            case 'boolean':
                return [
                    { key: true, name: 'true' },
                    { key: false, name: 'false' },
                ];
            default:
                return [];
        }
    };

    /**
     * Checks if all conditions are marked as satisfied
     *
     * @param dataset - The dataset to check
     *
     * @returns true if all conditions are marked as satisfied, and false otherwise
     */
    function isDatasetSatisfied(dataset: any) {
        return dataset && dataset.dataQueries.filter((condition: any) => !condition.satisfied).length === 0;
    }

    /**
     * In case of a required combination of datasets, returns if the required
     * datasets are selected
     *
     * @param dataset - Main dataset to examine
     * @param combinedDatasetsInformation - Already combined datasets
     * @param selectedDatasetIds - A list of the selected datasets
     *
     * @returns A list of selected and combined datasets
     */
    const datasetIdsAlreadyCombinedInAndSelected = (
        dataset: any,
        combinedDatasetsInformation: any,
        selectedDatasetIds: number[],
    ) => {
        const datasetIds: number[] = [];
        Object.keys(combinedDatasetsInformation).forEach((foreignDatasetIdString: string) => {
            const foreignDatasetId = parseInt(foreignDatasetIdString, 10);
            const comboInformation = combinedDatasetsInformation[foreignDatasetId];
            if (comboInformation.datasetId === dataset.id && selectedDatasetIds.includes(foreignDatasetId)) {
                datasetIds.push(foreignDatasetId);
            }
        });
        return datasetIds;
    };

    /**
     * Performs validation of the selected datasets and whether
     * the user can proceed to saving to the next step
     *
     * @param datasetIds - A list of ddatasetIds selected to check
     * @param datasetsMap - An id to dataset map
     * @param combinedDatasetsInformation - Information of which dataset is combined with which
     *
     * @returns An error message if any validation rule fails, otherwise if successful returns null
     */
    const selectedDatasetAndQueryError = (
        datasetIds: number[],
        datasetsMap: any,
        combinedDatasetsInformation: any,
    ): string | null => {
        const alreadySelectedCombinedDatasets: number[] = [];
        for (let d = 0; d < datasetIds.length; d++) {
            const dataset = datasetsMap[datasetIds[d]];
            if (dataset && !isDatasetSatisfied(dataset)) {
                if (
                    !S.has(dataset.id, combinedDatasetsInformation) &&
                    datasetIdsAlreadyCombinedInAndSelected(dataset, combinedDatasetsInformation, datasetIds).length ===
                        0
                ) {
                    return `The selected dataset '${dataset.name}' requires combination criteria to be provided`;
                }
                if (
                    S.has(dataset.id, combinedDatasetsInformation) &&
                    !datasetIds.includes(combinedDatasetsInformation[dataset.id].datasetId)
                ) {
                    return `The selected dataset '${dataset.name}' requires also dataset '${
                        combinedDatasetsInformation[dataset.id].datasetName
                    }' to be selected in ordered to be combined before proceeding`;
                }
                if (datasetIds.length !== 2) {
                    return `Since the selected dataset '${dataset.name}' is to be combined with dataset '${
                        combinedDatasetsInformation[dataset.id].datasetName
                    }' you can only select two datasets to proceed`;
                }

                if (S.has(dataset.id, combinedDatasetsInformation)) {
                    if (alreadySelectedCombinedDatasets.includes(combinedDatasetsInformation[dataset.id].datasetId)) {
                        const datasetConfigutration = combinedDatasetsInformation[dataset.id];
                        const reverseConfiguration =
                            combinedDatasetsInformation[combinedDatasetsInformation[dataset.id].datasetId];
                        if (
                            datasetConfigutration.localField !== reverseConfiguration.foreignField ||
                            datasetConfigutration.foreignField !== reverseConfiguration.localField ||
                            datasetConfigutration.overridingDataset !== reverseConfiguration.overridingDataset
                        )
                            return `The datasets you selected ('${dataset.name}' and '${datasetConfigutration.datasetName}') to combine both have defined combine criteria but that do not match.`;
                    }

                    alreadySelectedCombinedDatasets.push(dataset.id);
                }
            }
        }
        return null;
    };

    /**
     * Returns the full name of a concept
     *
     * @param datasetFields - All dataset fields
     * @param field - The field to check
     *
     * @returns The full path of the concept
     */
    function conceptFullName(datasetFields: Array<string>, field: string) {
        return datasetFields.filter(
            (datasetField: string) =>
                datasetField.endsWith(field) || datasetField.endsWith(field[0].toUpperCase() + field.slice(1)),
        )[0];
    }

    /**
     * Returns a list of concept full names
     *
     * @param datasetFields - All dataset fields
     * @param selectedFields - Selected fields of interest to get the full name for
     *
     * @returns A list of full paths for the selected concetps
     */
    function conceptsFullNames(datasetFields: Array<string>, selectedFields: Array<string>) {
        return selectedFields.map((field: string) => conceptFullName(datasetFields, field));
    }

    /**
     * Builds the query using the full names of the concepts included
     *
     * @param query - The query to transform
     * @param datasetFields - All dataset fields
     *
     * @returns A query with the concepts full names
     */
    function queryFullNames(query: any, datasetFields: Array<string>) {
        const tempQuery = R.clone(query);
        if (tempQuery.conditions) {
            tempQuery.conditions = tempQuery.conditions.map((q: any) => queryFullNames(q, datasetFields));
            return tempQuery;
        }
        if (S.has('concept', tempQuery)) {
            tempQuery.concept = conceptFullName(datasetFields, tempQuery.concept);
            return tempQuery;
        }
        return null;
    }

    /**
     * Builds a map of concept uids to concepts
     * @param dataModels - The data models to include
     * @param parentName - The name of the parent concept
     *
     * @returns A map of concept uids to their corresponding concept
     */
    function modelUidMap(dataModels: any[], parentName: string | null = null): any {
        return dataModels.reduce((acc: any, concept: any) => {
            if (S.has('children', concept)) {
                const childlessConcept = R.clone(concept);
                delete childlessConcept.children;
                acc[concept.uid] = { ...childlessConcept, parent: parentName };

                return { ...acc, ...modelUidMap(concept.children, concept.name) };
            }
            acc[concept.uid] = { ...concept, parent: parentName };
            return acc;
        }, {});
    }

    return {
        filterConcepts,
        typeOperants,
        typeOptions,
        selectedDatasetAndQueryError,
        conceptsFullNames,
        queryFullNames,
        modelUidMap,
    };
}
