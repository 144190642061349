



































































































import { defineComponent, computed, inject } from '@vue/composition-api';
import * as R from 'ramda';
import { useFilters } from '@/app/composable/filters';
import { SearchResultType, StreamingStatus } from '../constants';

export default defineComponent({
    name: 'SearchResultCard',
    props: {
        result: {
            type: Object,
            required: true,
        },
        selected: {
            type: Boolean,
            default: false,
        },
        mode: {
            type: String,
            default: null,
        },
        selectedResults: {
            type: Number,
            default: 0,
        },
        dataQuery: {
            type: Object,
            default: null,
        },
    },
    setup(props, { emit }: { emit: any; root: any }) {
        const { fromNow } = useFilters();
        const isFeatureEnabled: any = inject('isEnabled');

        const accessibilities = computed(() => {
            const result: SearchResultType[] = [];
            const accessibilityStrings = R.is(String, props.result.accessibility)
                ? [props.result.accessibility]
                : props.result.accessibility;
            accessibilityStrings.forEach((accessibilityString: string) => {
                const searchType = SearchResultType.findByName(accessibilityString);
                if (searchType && isFeatureEnabled(searchType.feature)) {
                    result.push(searchType);
                }
            });
            return result;
        });

        const buttons = computed(() => {
            const result: any[] = [];
            accessibilities.value.forEach((searchType: SearchResultType) => {
                result.push({ type: searchType, selected: props.selected && props.mode === searchType.key });
            });
            return result;
        });

        const enabled = (type: string) => {
            const buttonMode: SearchResultType | null = SearchResultType.findByKey(props.mode);
            if (!buttonMode || type === buttonMode.key) {
                if (buttonMode && !buttonMode.allowsMultiple() && props.selectedResults > 0 && !props.selected) {
                    return false;
                }
                return !(
                    type === SearchResultType.KAFKA.key &&
                    props.result.streamingStatus &&
                    props.result.streamingStatus === StreamingStatus.Completed
                );
            }

            return false;
        };

        const toggleButton = (event: { currentTarget: any }) => {
            const buttonTypeString = event.currentTarget.getAttribute('data-id');
            if (!enabled(buttonTypeString)) {
                return;
            }

            if (props.selected) {
                emit('remove-from-results', props.result.id, buttonTypeString, null);
            } else {
                emit('add-to-results', props.result.id, buttonTypeString, props.result);
            }
        };

        return {
            fromNow,
            buttons,
            enabled,
            toggleButton,
        };
    },
});
