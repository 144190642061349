


















































































































































































import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { useAxios } from '@vue-composable/axios';
import { OrbitSpinner } from 'epic-spinners';
import { FormBlock, TwAccordion, TwAccordionCard, SvgImage, JsonEditor } from '@/app/components';
import AccessTokenModal from '@/modules/user/views/AccessTokenModal.vue';
import { RetrieveAPI } from '../../api';

export default defineComponent({
    name: 'FileQueryResults',
    components: {
        FormBlock,
        TwAccordion,
        TwAccordionCard,
        AccessTokenModal,
        JsonEditor,
        OrbitSpinner,
        SvgImage,
    },
    props: {
        datasets: {
            type: Object,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
    },
    setup(props) {
        const assetFileUrls = ref<any>([]);
        const showAccessTokenModal = ref(false);
        const response = ref<string | null>(null);
        const apiUrl = process.env.VUE_APP_BACKEND_URL || '';

        const { exec, loading, error } = useAxios(true);

        const datasetId = computed(() => {
            return parseInt(Object.keys(props.datasets)[0], 10);
        });

        watch(
            () => datasetId.value,
            (id) => {
                let path = 'api/file';
                if (props.configuration.download === 'indirect') {
                    path = 'api/query/file';
                }
                exec(RetrieveAPI.getURLs(id, path)).then((res: any) => {
                    assetFileUrls.value = res.data;
                    response.value = JSON.stringify(assetFileUrls.value, null, '\t');
                });
            },
            { immediate: true },
        );

        return {
            assetFileUrls,
            loading,
            error,
            response,
            showAccessTokenModal,
            apiUrl,
            datasetId,
        };
    },
});
