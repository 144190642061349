








































































































































































































































































import { defineComponent, computed, reactive, ref } from '@vue/composition-api';
import { ClipboardCopyIcon } from '@vue-hero-icons/outline';
import { TwAccordion, TwAccordionCard, JsonEditor } from '@/app/components';
import AccessTokenModal from '@/modules/user/views/AccessTokenModal.vue';
import { SearchUtils } from '../../utils';

export default defineComponent({
    name: 'ConfigureDatasetQuery',
    components: {
        TwAccordion,
        TwAccordionCard,
        JsonEditor,
        ClipboardCopyIcon,
        AccessTokenModal,
    },
    props: {
        datasets: {
            type: Object,
            required: true,
        },
        configuration: {
            type: Object,
            required: true,
        },
        id: {
            type: String,
        },
    },
    setup(props, { emit }) {
        const showAccessTokenModal = ref(false);
        const urlToCopy = ref<any>(null);
        const apiUrl = process.env.VUE_APP_SEARCH_API_URL || '';

        const concepts = reactive(SearchUtils.flattenConcepts(props.datasets, props.configuration));

        const fields: any = computed(() => {
            return Object.values(concepts).reduce((accumulator: any, concept: any) => {
                return {
                    ...accumulator,
                    ...concept,
                };
            }, {});
        });

        // Calculates which query parameters are selected
        const selectedQueryParameters = computed(() => {
            const result: string[] = [];
            Object.keys(concepts).forEach((domainId) => {
                const domain = concepts[domainId];
                Object.keys(domain).forEach((conceptKey) => {
                    const concept = domain[conceptKey];
                    if (concept.queryParameter && concept.selected && result.indexOf(conceptKey) === -1) {
                        result.push(conceptKey);
                    }
                });
            });

            return result;
        });

        const copyToClipboard = (inputId: string) => {
            urlToCopy.value = document.querySelector(inputId);

            if (urlToCopy.value.value) {
                const el = document.createElement('textarea');
                el.value = urlToCopy.value.value;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);
            }
        };

        // Computes the sample url and params to be shown
        const computedApi = computed(() => {
            return SearchUtils.computeApi(apiUrl, props.id || '', [...selectedQueryParameters.value], fields.value);
        });
        return { computedApi, emit, showAccessTokenModal, copyToClipboard };
    },
});
