
































































































































































import * as R from 'ramda';
import { defineComponent, ref, computed, watch } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';
import { S } from '@/app/utilities';
import { InputErrorIcon } from '@/app/components';

extend('required', {
    ...required,
    message: (field) => {
        return `The ${field} is required`;
    },
});

export default defineComponent({
    name: 'CombineDatasets',
    props: {
        dataset: {
            type: Object,
            required: true,
        },
        datasets: {
            type: Array,
            required: true,
        },
        data: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
    components: { ValidationProvider, ValidationObserver, InputErrorIcon },
    setup(props, { emit }) {
        const selectedForeignDatasetId = ref<number>(
            S.has('datasetId', props.data) ? props.data.datasetId : (props.datasets as Array<any>)[0].id,
        );
        const selectedLocalField = ref<number>(S.has('localField', props.data) ? props.data.localField : null);
        const selectedForeignField = ref<number>(S.has('foreignField', props.data) ? props.data.foreignField : null);
        const selectedOverridingDatasetId = ref<number>(
            S.has('overridingDataset', props.data) ? props.data.overridingDataset : props.dataset.id,
        );

        const selectedForeignDataset = computed(() => {
            for (let d = 0; d < props.datasets.length; d++) {
                const dataset: any = props.datasets[d];
                if (dataset.id === selectedForeignDatasetId.value) {
                    return dataset;
                }
            }

            return null;
        });

        const hasData = computed(() => !R.isNil(props.data) && !R.isEmpty(props.data));

        const combine = () => {
            emit('combine', {
                datasetId: selectedForeignDataset.value.id,
                datasetName: selectedForeignDataset.value.name,
                localField: selectedLocalField.value,
                foreignField: selectedForeignField.value,
                overridingDataset: selectedOverridingDatasetId.value,
            });
        };

        const clear = () => {
            emit('clear');
        };

        const cancel = () => {
            emit('cancel');
        };

        /**
         * Watches in case of any data changes since the combine datasets pane
         * might remain open while the user chooses to view a different dataset
         * combination. This watcher updates the appropriate variables to change the view
         */
        watch(
            () => props.data,
            (updatedData: any) => {
                selectedForeignDatasetId.value = S.has('datasetId', updatedData)
                    ? updatedData.datasetId
                    : (props.datasets as Array<any>)[0].id;
                selectedLocalField.value = S.has('localField', updatedData) ? updatedData.localField : null;
                selectedForeignField.value = S.has('foreignField', updatedData) ? updatedData.foreignField : null;
                selectedOverridingDatasetId.value = S.has('overridingDataset', updatedData)
                    ? updatedData.overridingDataset
                    : props.dataset.id;
            },
        );

        return {
            selectedForeignDatasetId,
            selectedLocalField,
            selectedForeignField,
            selectedForeignDataset,
            selectedOverridingDatasetId,
            hasData,
            combine,
            clear,
            cancel,
        };
    },
});
