




























































































































































































































import { defineComponent, computed } from '@vue/composition-api';
import { v4 as uuidv4 } from 'uuid';
import MatchedConditionsViewer from './MatchedConditionsViewer.vue';

/**
 * Renders the footer under each search result in case we have a data query
 */
export default defineComponent({
    name: 'SearchResultcomputedFooter',
    props: {
        result: { type: Object, required: true },
        isShowingCombinedData: {
            type: Boolean,
            default: false,
        },
        isShowingPreview: {
            type: Boolean,
            default: false,
        },
        isShowingJoinedPreview: {
            type: Boolean,
            default: false,
        },
        previewData: {
            type: Object,
        },
        combinationData: {
            type: Object,
        },
        dataQuery: { type: Object },
    },
    components: { MatchedConditionsViewer },
    setup(props, { emit }) {
        const canBeCombined = computed(() => {
            for (let q = 0; q < props.result.dataQueries.length; q++) {
                const query = props.result.dataQueries[q];
                if (!query.satisfied && query.canBeSatisfiedBy.length > 0) {
                    return true;
                }
            }
            return false;
        });

        const isPartialMatch = computed(() => {
            for (let q = 0; q < props.result.dataQueries.length; q++) {
                const query = props.result.dataQueries[q];
                if (!query.satisfied) {
                    return true;
                }
            }
            return false;
        });

        const combineDatasetClicked = () => {
            emit('combine-dataset');
        };

        const viewDatasetCombinationClicked = () => {
            emit('view-combine-dataset');
        };

        const previewDataClicked = () => {
            emit('preview-data');
        };

        return {
            canBeCombined,
            isPartialMatch,
            uuidv4,
            combineDatasetClicked,
            viewDatasetCombinationClicked,
            previewDataClicked,
        };
    },
});
