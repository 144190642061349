



























































































































































































import { defineComponent } from '@vue/composition-api';
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
import { required, integer } from 'vee-validate/dist/rules';

import { InputErrorIcon, DateTimePicker } from '@/app/components';
import { QueryOperant } from '@/app/constants';
import { useModel } from '../composable';

const doubleValidator = {
    message(field: string) {
        return `The ${field} must be a double`;
    },
    validate(value: any) {
        try {
            parseFloat(value);
            return true;
        } catch {
            return false;
        }
    },
};

extend('required', {
    ...required,
    message: (field) => {
        return `The ${field} is required`;
    },
});
extend('integer', {
    ...integer,
    message: (field) => {
        return `The ${field} must be an integer`;
    },
});

extend('double', doubleValidator);

export default defineComponent({
    name: 'ConditionEditor',
    props: {
        data: {
            type: Object,
            required: true,
        },
        concepts: {
            type: Object,
            required: true,
        },
    },
    components: { InputErrorIcon, ValidationProvider, ValidationObserver, DateTimePicker },
    setup(props) {
        const { typeOperants, typeOptions } = useModel();

        const getType = (concept: string): string => {
            return props.concepts[concept].type;
        };

        const getTypeOperants = (concept: string): QueryOperant[] => {
            return typeOperants(getType(concept));
        };

        const getTypeOptions = (concept: string): { key: any; name: string }[] => {
            return typeOptions(getType(concept));
        };

        return { getTypeOperants, getTypeOptions, getType };
    },
});
